.App {
  text-align: center;
  background-color: #f0f0e0;
  position: relative;
  overflow-x: hidden;
}

/* bootstrapで実現不可能なレスポンシブ対応を手書き */

@media (min-width: 992px) {
  #ae-section {
    height: calc(100vh - 2rem - 5.25rem - 12.8125rem);
    overflow-y: auto;
    overflow-x: hidden;
  }
  #three-section {
    padding: 0rem;
    position: relative;
    height: calc(100vh - 6.25rem);
  }
  #canvas-cover {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    height: calc(100vh - 6.25rem);
    z-index: 10;
  }
  .header-title {
    width: 22rem;
  }
  .header-name {
    font-size: 2rem;
  }
  .ability-modal {
    width: 320px;
  }
  .mainSlotModal {
    height: calc(280px + 2rem);
    position: absolute;
    left: -125px;
    top: 72px;
    z-index: 5;
  }
  .mainSlotModal:before {
    position: absolute;
    top: -20px;
    left: 138px;
  }
  .modal-close-btn {
    margin: 2px 0px 0px 270px;
  }
  /* サブスロットモーダル */
  .subSlotModal-left {
    height: calc(210px + 2.6rem);
    left: -180px;
    top: 64px;
    position: absolute;
  }
  .subSlotModal-mid {
    height: calc(210px + 2.6rem);
    left: -226px;
    top: 64px;
    position: absolute;
  }
  .subSlotModal-right {
    height: calc(210px + 2.6rem);
    left: -272px;
    top: 64px;
    position: absolute;
  }
  .subSlotModal-left:before {
    position: absolute;
    top: -20px;
    left: 190px;
  }
  .subSlotModal-mid:before {
    position: absolute;
    top: -20px;
    left: 237px;
  }
  .subSlotModal-right:before {
    position: absolute;
    top: -20px;
    left: 283px;
  }
  .gear-modal {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 1080px) {
  .gear-card {
    width: 200px;
  }
  .gear-header {
    width: 200px;
  }
  .mainSlotModal-wrapper {
    position: absolute;
    bottom: 5px;
    left: 6px;
  }
  .subSlotModal-wrapper-left {
    position: absolute;
    bottom: 5px;
    left: 60px;
  }
  .subSlotModal-wrapper-mid {
    position: absolute;
    bottom: 5px;
    left: 106px;
  }
  .subSlotModal-wrapper-right {
    position: absolute;
    bottom: 5px;
    left: 152px;
  }
}
@media (max-width: 1079.8px) {
  .gear-card {
    width: 184px;
  }
  .gear-header {
    width: 184px;
  }
  .mainSlotModal-wrapper {
    position: absolute;
    bottom: 3px;
    left: 2px;
  }
  .subSlotModal-wrapper-left {
    position: absolute;
    bottom: 3px;
    left: 53px;
  }
  .subSlotModal-wrapper-mid {
    position: absolute;
    bottom: 3px;
    left: 97px;
  }
  .subSlotModal-wrapper-right {
    position: absolute;
    bottom: 3px;
    left: 141px;
  }
}
@media (max-width: 991.8px) {
  .gear-card {
    width: 200px;
  }
  .gear-header {
    width: 200px;
  }
  .header-name {
    font-size: 2rem;
  }
  /* アビリティ効果部分 */
  #ae-section {
    width: 95vw;
    margin-left: calc(-95vw + 100%);
  }
  #three-section {
    padding: 0rem;
    position: relative;
    height: calc(615px + 4rem);
  }
  #canvas-cover {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    height: calc(615px + 4rem);
    z-index: 10;
  }
  .mainSlotModal-wrapper {
    position: absolute;
    bottom: 5px;
    left: 6px;
  }
  .subSlotModal-wrapper-left {
    position: absolute;
    bottom: 5px;
    left: 60px;
  }
  .subSlotModal-wrapper-mid {
    position: absolute;
    bottom: 5px;
    left: 106px;
  }
  .subSlotModal-wrapper-right {
    position: absolute;
    bottom: 5px;
    left: 152px;
  }
  .ability-modal {
    width: 200px;
  }
  .mainSlotModal {
    height: calc(450px + 2.6rem);
    position: absolute;
    left: -6px;
    top: 72px;
    z-index: 5;
  }
  #mainSlotModal2 {
    height: calc(390px + 2.6rem);
  }
  .mainSlotModal:before {
    position: absolute;
    top: -20px;
    left: 20px;
  }
  .header-title {
    width: 22rem;
  }
  .modal-close-btn {
    margin: 2px 0px 0px 152px;
  }
  /* サブスロットモーダル */
  .subSlotModal-left {
    height: calc(330px + 2.6rem);
    left: -60px;
    top: 64px;
    position: absolute;
  }
  .subSlotModal-mid {
    height: calc(330px + 2.6rem);
    left: -106px;
    top: 64px;
    position: absolute;
  }
  .subSlotModal-right {
    height: calc(330px + 2.6rem);
    left: -152px;
    top: 64px;
    position: absolute;
  }
  .subSlotModal-left:before {
    position: absolute;
    top: -20px;
    left: 70px;
  }
  .subSlotModal-mid:before {
    position: absolute;
    top: -20px;
    left: 117px;
  }
  .subSlotModal-right:before {
    position: absolute;
    top: -20px;
    left: 164px;
  }
  .gear-modal {
    width: 50vw;
    margin-left: calc(-50vw + 100%);
  }
}

@media (max-width: 575.8px) {
  /* アビリティ効果部分 */
  #ae-section {
    width: 100%;
    margin: auto;
  }
  /* ギアモーダル */
  .gear-modal {
    width: 100%;
    margin: auto;
  }
  .header-name {
    font-size: 1.25rem;
    margin-top: 0.5rem;
  }
  .header-title {
    width: 16rem;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'splType01';
  src: url(./Splatfont.woff) format('woff');
}

@font-face {
  font-family: 'splType02';
  src: url(./Splatfont2.woff) format('woff');
}
/* ローダー */
#loader-content {
  position: absolute;
  top: 45%;
  left: 0px;
  margin-top: 5px;
  z-index: 30;
  width: 100%;
}

/* ヘッダー */
#header {
  height: 4rem;
  background: #343a40;
  position: relative;
}
/* ヘッダー画像 */
.header-image {
  height: 2.5rem;
  width: 2.5rem;
  margin-top: 0.25rem;
}
/* サイト名 */
.header-name {
  width: 17rem;
  color: white;
}
.header-title {
  margin-left: auto;
  margin-right: auto;
  padding-top: 0.5rem;
}
/* 言語の選択 */
#lang-selector {
  position: absolute;
  top: 0.85rem;
  right: 10px;
  border-radius: 8px;
}

#lang-selector:hover {
  background: rgb(107, 107, 107);
}

#lang-selector svg {
  z-index: 1;
}

#lang-selector button{
  position: relative;
  height: 35px;
  width: 50px;
  background-color: transparent;
  z-index: 2;
  color: transparent;
  border-radius: 8px;
  border: none;
  outline: 0px;
}

#lang-selector select:active{
  border: none;
}

#lang-selector option {
  background: #000;
  color: white;
}

/*  */
/* フッター */
/*  */
footer {
  height: 2.25rem;
  background: #343a40;
  padding-top: 0.25rem;
}
/* fixed-bottomにすることでフッターがcontainerに重なってしまうのでdiv要素に高さを設定 */
#footer-height {
  height: 2.25rem;
}

.twitter-link {
  text-decoration: none;
  color: white;
}
.twitter-link:hover {
  color: #dee2e6;
}
.twitter-link:hover svg path {
  fill: #dee2e6;
}
.github-link {
  text-decoration: none;
  color: white;
  margin-right: 1rem;
}
.github-link:hover {
  color: #dee2e6;
}
.github-link:hover svg path {
  fill: #dee2e6;
}
.about {
  cursor: pointer;
  color: white;
  text-decoration: none;
  margin-right: 1rem;
}
.about:hover {
  color: #dee2e6;
}
.about:hover svg path {
  fill: #dee2e6;
}
#aboutModal p {
  font-size: 1.25rem;
}

/* アタマギア関連に使うストライプ模様 */
.head-stripe {
  background: repeating-linear-gradient(
    45deg,
    #d63e53,
    #d63e53 20px,
    #f0624a 20px,
    #f0624a 40px
  );
}

/* フクギア関連に使うストライプ模様 */
.clothes-stripe {
  background: repeating-linear-gradient(
    45deg,
    #19e1ba,
    #19e1ba 20px,
    #06be8a 20px,
    #06be8a 40px
  );
}

/* クツギア関連に使うストライプ模様 */
.shoes-stripe {
  background: repeating-linear-gradient(
    45deg,
    #f07807,
    #f07807 20px,
    #d25012 20px,
    #d25012 40px
  );
}

/* アビリティ関連に使うストライプ模様 */

.gray-stripe {
  background: repeating-linear-gradient(
    45deg,
    #808080,
    #808080 20px,
    #6b6b6b 20px,
    #6b6b6b 40px
  );
}
.gray-stripe-dense {
  background: repeating-linear-gradient(
    45deg,
    #333333,
    #333333 20px,
    #4d4d4d 20px,
    #4d4d4d 40px
  );
}

.font-type1 {
  font-family: splType01;
}

.font-type2 {
  font-family: splType02;
}

#gear-and-ability {
  position: relative;
}

/*  */
/* ギア関連  */
/*  */

/* アタマ　フク　クツで共通の大きさ */
/* widthはレスポンシブ対応 */
.gear-card {
  height: 12.8125rem;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

/* ギア表示のヘッダー部分 */
/* widthはレスポンシブ対応 */
.gear-header {
  height: 2.55rem;
  background-color: rgba(0, 0, 0, 0.7);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  color: white;
  font-size: 1.75rem;
  margin: 0px;
}

/* ギアパワーのリセットスイッチ */
.ability-reset {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 55px;
  right: 5px;
  cursor: pointer;
}
/* ホバーしたとき拡大と回転 */
.ability-reset:hover svg {
  transition: 0.1s;
  transform: rotate(15deg) scale(1.2, 1.2);
}
/* クリックで回転 */
.ability-reset:active svg {
  transition: 0.1s;
  transform: rotate(-15deg) scale(1.2, 1.2);
}
/* ツールチップ */
.ability-reset-tooltip {
  width: 7rem;
  height: 1.8rem;
  display: none;
  position: absolute;
  bottom: -1rem;
  right: 2.5rem;
  z-index: 2;
  padding: 0.3rem 0.5rem;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 0.3rem;
  font-size: 0.9rem;
  user-select: none;
}
/* ツールチップの矢印 */
.ability-reset-tooltip:after {
  border-top: 0.3rem solid transparent;
  border-bottom: 0.3rem solid transparent;
  border-left: 0.4rem solid rgba(0, 0, 0, 0.8);
  content: '';
  display: block;
  width: 0.5rem;
  position: absolute;
  top: 0.6rem;
  right: -0.4rem;
}
/*　ホバーしたときツールチップを表示 */
.ability-reset:hover .ability-reset-tooltip {
  display: block;
}

/* ギア画像の大きさ */
.gear-img {
  width: 130px;
  height: 110px;
  position: absolute;
  bottom: 3.45rem;
  left: 34px;
  cursor: pointer;
}

.gear-img img {
  width: 110px;
  height: 110px;
  margin: auto;
}

.head-tooltip {
  width: 110px;
  height: 110px;
  margin: auto;
  cursor: pointer;
  user-select: none;
}

/*スロット部分の高さ指定*/
.slot-row {
  height: 60px;
  position: relative;
}

/* スロットの位置と大きさ調整 */
.main-slot {
  height: 48px;
  width: 48px;
  cursor: pointer;
  position: relative;
}
/* モーダルを開く */
.mainSlotModal-wrapper {
  height: 48px;
  width: 48px;
}

/*ギアアビリティを表示するときのスタイルをスロット、アイコン共に指定*/
.ability-icon {
  border-radius: 50%;
  background: #000;
  /*立体感演出のために明るい影をつける*/
  border: 2px solid #888;
  border-right: 0px;
  border-bottom: 0px;
  /*要素のフレームの周囲にシャドウ効果*/
  box-shadow: 0 0 0 1px #000;
}

/*メインスロットの画像の大きさと位置調整*/
.mainslot-img {
  height: 43px;
  width: 43px;
  position: absolute;
  top: 1px;
  left: 1px;
}

/* サブスロットのsvgの位置調整 */

/*サブスロットの大きさ調整*/
.sub-slot {
  height: 40px;
  width: 40px;
  cursor: pointer;
  position: relative;
}

/*サブスロットの画像の大きさと位置調整*/
.subslot-img {
  height: 36px;
  width: 36px;
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 4;
}
/* モーダルの位置調整のための要素 */
.subSlotModal-wrapper-left {
  height: 40px;
  width: 40px;
}
.subSlotModal-wrapper-mid {
  height: 40px;
  width: 40px;
}
.subSlotModal-wrapper-right {
  height: 40px;
  width: 40px;
}

/*  */
/* アタマギアモーダル */
/*  */

.chosen-gear-icon {
  width: 180px;
  height: 182px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: repeating-linear-gradient(
    45deg,
    #fafae2,
    #fafae2 20px,
    #e6e7c7 20px,
    #e6e7c7 40px
  );
  position: relative;
  margin-top: 0px;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.chosenGear-text {
  margin: 40px auto 0px auto;
}
/* 選ばれたギアの名前表示部分 */
.chosenGear-name {
  width: 180px;
  background: rgba(0, 0, 0, 0.8);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 0.25rem 0.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 0px;
}
/* ツールチップ */
.fav-ability-tooltip {
  width: 8rem;
  display: none;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: -3.5rem;
  left: -70%;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  user-select: none;
}
/* ツールチップの矢印 */
.fav-ability-tooltip:after {
  display: block;
  border-left: 0.3rem solid transparent;
  border-right: 0.3rem solid transparent;
  border-bottom: 0.5rem solid rgba(0, 0, 0, 0.8);
  width: 0.6rem;
  position: absolute;
  top: -0.5rem;
  left: 45%;
  content: '';
}
/* ホバーしたときに表示 */
.brand-ability:hover .fav-ability-tooltip {
  display: block;
}

/* アタマ、フク、クツのモーダル全体で共通の高さ */
.gear-modal {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 31;
}

.gears-display-area {
  height: calc(100vh - 16.25rem);
}

.gearicons-section {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 2%;
  padding-top: 30px;
  margin-left: 10px;
}
/* ギアモーダルのフッター */
.gearModal-footer {
  height: 2.25rem;
}

/* デフォルトのギアパワー */
.default-ability {
  width: 40px;
  height: 40px;
  position: relative;
  margin: auto;
}

.default-ability-img {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 1px;
  left: 2px;
}

.gear-icon {
  width: 115px;
  height: 110px;
  border-radius: 10px;
  background: repeating-linear-gradient(
    45deg,
    #fafae2,
    #fafae2 20px,
    #e6e7c7 20px,
    #e6e7c7 40px
  );
  cursor: pointer;
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
/* ギアの画像 */
.gear-icon-img {
  width: 110px;
  height: 110px;
}

/* ギアのフッター */
.gear-icon-footer {
  width: 180px;
  height: 55px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

/* フクギアのフッター */
.clothesgear-icon-footer {
  width: 150px;
  height: 55px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: repeating-linear-gradient(
    45deg,
    #19e1ba,
    #19e1ba 20px,
    #06be8a 20px,
    #06be8a 40px
  );
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
}

/* クツギアのフッター */
.shoesgear-icon-footer {
  width: 150px;
  height: 55px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: repeating-linear-gradient(
    45deg,
    #f07807,
    #f07807 20px,
    #d25012 20px,
    #d25012 40px
  );
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
}

.brand-img {
  width: 40px;
  height: 40px;
}
.brand-img-container {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.brand-tooltip-container {
  position: absolute;
  top: 0px;
  left: 40px;
  width: 140px;
  padding-left: 0.4rem;
  padding-top: 5px;
  display: none;
}

.brand-tooltip {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 0.25rem;
  padding: 0.2rem 0.4rem;
}
.brand-tooltip:after {
  display: block;
  width: 0.4rem;
  border-top: 0.25rem solid transparent;
  border-bottom: 0.25rem solid transparent;
  border-right: 0.4rem solid rgba(0, 0, 0, 0.8);
  content: '';
  position: absolute;
  top: 0.8rem;
  left: 0rem;
}

.brand-img-container:hover .brand-tooltip-container {
  display: block;
}

.brand-img-small {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0px;
  left: 3px;
}

.img-gear-icon-ability {
  width: 30px;
  height: 30px;
}

.brand-ability {
  width: 50px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 13px;
  padding-top: 3px;
  margin: auto;
  position: relative;
}

/**/
/*ブキ関連*/
/**/
.select-index {
  margin-left: 10px;
  margin-right: 2px;
}

.selectbox {
  margin-left: 2px;
}

.weapon-icon {
  width: 150px;
  height: 182px;
  border-radius: 10px;
  background: repeating-linear-gradient(
    45deg,
    #fafae2,
    #fafae2 20px,
    #e6e7c7 20px,
    #e6e7c7 40px
  );
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.img-weapon {
  width: 128px;
  height: 128px;
}

.sub-sp-bg {
  width: 150px;
  height: 54px;
  background: repeating-linear-gradient(
    45deg,
    #dbe826,
    #dbe826 20px,
    #c4d412 20px,
    #c4d412 40px
  );
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: auto;
}

.sub-sp {
  position: relative;
  width: 56px;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 13px;
}

.img-sub-sp {
  position: absolute;
  top: 4px;
  left: 8px;
  width: 40px;
  height: 40px;
}
/* ブキモーダル */

/* ブキモーダルのツールチップ */
.weapon-icon-tooltip-container {
  display: none;
  position: absolute;
  top: -2.15rem;
  left: -25px;
  width: 200px;
}

.weapon-icon-tooltip {
  height: 1.8rem;
  display: inline-block;
  z-index: 2;
  padding: 0.2rem 0.4rem;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 0.3em;
  font-size: 0.9rem;
  user-select: none;
  margin: 0px;
}

.weapon-icon-tooltip:after {
  width: 0.5rem;
  content: "";
  display: block;
  z-index: 2;
  border-top: 0.4rem solid rgba(0, 0, 0, 0.8);
  border-right: 0.3rem solid transparent;
  border-left: 0.3rem solid transparent;
  position: absolute;
  bottom: -0.4rem;
  left: 47%;
}


.weapon-icon:hover .weapon-icon-tooltip-container {
  display: block;
}

/*ギアパワーのスイッチ*/

.turned-on,
.turned-off {
  position: relative;
  width: 40px;
  height: 20px;
  margin-top: 2px;
}

.switch-base {
  /*position: absolute;*/
  left: 0;
  top: 0;
  /*width: 100%;*/
  /*height: 100%;*/
  cursor: pointer;
  width: 40px;
  height: 20px;
  background: #ddba2c;
  position: relative;
  display: inline-block;
  border-radius: 46px;
  transition: 0.4s;
  box-sizing: border-box;
}

.turned-off .switch-base {
  border-color: #ccc;
  background: #ccc;
}

.turned-on #switch-circle {
  position: absolute;
  width: 18px;
  height: 18px;
  background: #ffffff;
  top: 1px;
  left: 21px;
  border-radius: 9px;
  transition: 0.2s;
  cursor: pointer;
}

.turned-off #switch-circle {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 1px;
  left: 1px;
  border-radius: 9px;
  transition: 0.2s;
  background: #ffffff;
  cursor: pointer;
}

.modal-close-btn {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.modal-close-btn:hover path,
.modal-close-btn-btm:hover path {
  fill: #fff;
}

/*SCボタン*/
.color-btn,
.selected {
  margin: auto;
  height: 110px;
  width: 120px;
}

.color-btn-svg {
  cursor: pointer;
}

.color-btn-svg:hover,
.selected .color-btn-svg {
  transition-duration: 0.1s;
  transform: scale(1.05, 1.05);
}
/* 初期状態で非表示 */
.color-btn-hover {
  display: none;
}
/* 選択された状態で非表示 */
.selected .color-btn-svg .color-btn-default {
  display: none;
}
/* 選択された状態で表示 */
.selected .color-btn-svg .color-btn-hover {
  display: block;
}
/* ホバーしたとき非表示 */
.color-btn .color-btn-svg:hover .color-btn-default {
  display: none;
}
/* ホバーしたとき表示 */
.color-btn .color-btn-svg:hover .color-btn-hover {
  display: block;
}
/* マウスボタンを押し込んでいるとき表示 */
.color-btn .color-btn-svg:active .color-btn-default {
  display: block;
}
/* マウスボタンを押し込んでいるとき非表示 */
.color-btn .color-btn-svg:active .color-btn-hover {
  display: none;
}

/*
CTボタン
*/
.CT-btn,
.CT-selected {
  width: 15rem;
  height: 10.4rem;
  margin: auto;
}

.CT-btn-svg {
  width: 14.7rem;
  height: 9.7rem;
}

/*ボタン内のテキスト*/
.CT-text-default {
  user-select: none;
}

.CT-text-hover {
  user-select: none;
}

.CT-btn-svg:hover .CT-text-hover {
  fill: white;
}
/*CTボタンのアニメーション*/
.CT-btn-svg {
  cursor: pointer;
  opacity: 0.8;
}

.CT-btn-svg:hover {
  transition: 0.1s;
  transform: scale(1.05, 1.05);
}

.CT-btn-svg:hover .CT-btn-default,
.CT-btn-svg:hover .CT-text-default,
.CT-btn-svg:active .CT-btn-hover {
  fill: none;
}

.CT-btn-svg:hover .CT-btn-hover,
.CT-btn-svg:active .CT-btn-default {
  fill: #d9dc20;
}

.CT-btn-svg:active .CT-btn-hover {
  fill: none;
}

.CT-selected .CT-btn-svg {
  transform: scale(1.05, 1.05);
}

.CT-selected .CT-btn-svg .CT-btn-default {
  fill: none;
}

.CT-selected .CT-btn-svg .CT-btn-hover {
  fill: #d9dc20;
}

.CT-selected .CT-btn-svg .CT-text-default {
  fill: none;
}

.CT-selected .CT-btn-svg .CT-text-hover {
  fill: white;
}
/*
HSボタン
*/
.HS-btn,
.HS-selected,
.HS-ML-btn,
.HS-ML-selected {
  width: 140px;
  height: 200px;
  margin: auto;
}

.HS-btn-svg {
  width: 120px;
  height: 170px;
  cursor: pointer;
}

.OFML-HS-svg {
  width: 130px;
  height: 140px;
}

.OML-HS-svg {
  width: 110px;
  height: 150px;
}

.HS-btn-svg:hover {
  transition: 0.1s;
  transform: rotate(-15deg) scale(1.1, 1.1);
}

.HS-btn-svg:active {
  transition: 0.1s;
  transform: rotate(-15deg) scale(1, 1);
}

.HS-selected .HS-btn-svg {
  transform: rotate(-15deg) scale(1.1, 1.1);
}

.HS-selected .HS-btn-svg .HS-btn-hair {
  fill: #0cd900;
}

.HS-btn .HS-btn-svg:hover .HS-btn-hair {
  fill: #0cd900;
}

.HS-btn .HS-btn-svg:hover .HS-btn-geso-0,
.HS-btn .HS-btn-svg:hover .HS-btn-geso,
.HS-selected .HS-btn-svg:hover .HS-btn-geso-0,
.HS-selected .HS-btn-svg .HS-btn-geso-0,
.HS-selected .HS-btn-svg:hover .HS-btn-geso,
.HS-selected .HS-btn-svg .HS-btn-geso,
.HS-btn .HS-btn-svg:hover .HS-btn-suc,
.HS-selected .HS-btn-svg .HS-btn-suc {
  fill: #cff4db;
}

.HS-btn .HS-btn-svg:hover .HS-btn-geso-1,
.HS-selected .HS-btn-svg .HS-btn-geso-1 {
  fill: #63e398;
}

.HS-btn .HS-btn-svg:hover .HS-btn-hairband,
.HS-selected .HS-btn-svg .HS-btn-hairband {
  fill: #66e569;
}

.OFML-HS-btn .HS-btn-svg:hover .HS-btn-hair {
  fill: #ffb23d;
}

.HS-ML-btn .HS-btn-svg:hover .HS-btn-hair,
.HS-ML-selected .HS-btn-svg .HS-btn-hair {
  fill: #ff00b3;
}
.HS-ML-btn .HS-btn-svg:hover .HS-btn-hair-1,
.HS-ML-selected .HS-btn-svg .HS-btn-hair-1 {
  fill: #fd48ba;
}

.HS-ML-btn .HS-btn-svg:hover .HS-btn-geso,
.HS-ML-btn .HS-btn-svg:hover .HS-btn-hairband,
.HS-ML-selected .HS-btn-svg .HS-btn-geso,
.HS-ML-selected .HS-btn-svg .HS-btn-hairband {
  fill: #fecddd;
}

.HS-ML-selected .HS-btn-svg {
  transform: rotate(-15deg) scale(1.1, 1.1);
}

/*
Btmボタン
*/
.ML-Btm-btn,
.ML-Btm-selected,
.FML-Btm-btn,
.FML-Btm-selected {
  width: 130px;
  height: 150px;
  margin: auto;
}

.Btm-btn-svg {
  cursor: pointer;
}

.Btm-btn-svg:hover {
  transition: 0.1s;
  transform: rotate(-15deg) scale(1.1, 1.1);
}

.Btm-btn-svg:active {
  transition: 0.1s;
  transform: rotate(-15deg) scale(1, 1);
}

.FML-Btm-btn .Btm-btn-svg:hover .Btm-btn-color,
.FML-Btm-selected .Btm-btn-svg .Btm-btn-color {
  fill: #0cd900;
}

.ML-Btm-btn .Btm-btn-svg:hover .Btm-btn-color,
.ML-Btm-selected .Btm-btn-svg .Btm-btn-color {
  fill: #ff00b3;
}

.FML-Btm-selected .Btm-btn-svg,
.ML-Btm-selected .Btm-btn-svg {
  transform: rotate(-15deg) scale(1.1, 1.1);
}

/*  */
/*AEのスタイル*/
/*  */

#ae-display-area {
  border-radius: 30px;
}

.ae-card {
  border-bottom: dotted 3px #dee2e6;

  padding: 5px 0px;
  position: relative;
  width: 97%;
  margin-right: auto;
  margin-left: auto;
}
/* ヘッダー */
#ae-header {
  height: 2.25rem;
  background-color: rgba(0, 0, 0, 0.7);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  color: white;
  font-size: 1.5rem;
  margin: 0px;
}

.ae-part {
  margin: auto 0px;
  padding: 0px;
}

/* ギアパワーのアイコン */
.ap-ability-icon {
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  position: absolute;
  top: 0.3125rem;
  left: 0rem;
}

/* ギアパワーの画像 */
.ap-ability-img {
  width: 2.15rem;
  height: 2.15rem;
  position: absolute;
  top: 0.125rem;
  left: 0.125rem;
}

/* 1行のみの場合 */
.ae-text {
  text-align: right;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

/* １つの効果を2行で書く場合の1行目 */
.ae-text-first {
  margin-top: 6px;
  margin-bottom: 0rem;
  text-align: right;
}
/* １つの効果を2行で書く場合の2行目 */
.ae-text-second {
  margin-top: 6px;
  margin-bottom: 6px;
  text-align: right;
}

.ae-colon {
  margin-top: auto;
  margin-bottom: auto;
}

.ae-effect {
  margin-top: 0rem;
  margin-bottom: 0rem;
  text-align: left;
}

.ae-sub-img {
  position: relative;
  height: 50px;
  margin-left: 20px;
  margin-right: 20px;
}

#ae-section::-webkit-scrollbar,
.gearicons-section::-webkit-scrollbar {
  width: 10px;
}

#ae-section::-webkit-scrollbar-track,
.gearicons-section::-webkit-scrollbar-track {
  background: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 2px #777;
}

#ae-section::-webkit-scrollbar-thumb,
.gearicons-section::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  box-shadow: none;
}


#displayBtn-section {
  position: absolute;
  top: 45%;
  left: 0px;
  margin-top: 5px;
  z-index: 30;
  width: 100%;
}

#display-warning {
  width: 70%;
  margin: 50px auto 0px auto;
}
#btn-row {
  position: absolute;
  z-index: 11;
  width: 100%;
}
#character-btn {
  margin: 5px 5px 0px 5px;
}
#control-btn {
  margin-top: 5px;
}
#hideModel-btn {
  margin-top: 5px;
}


/*  */
/* ブキの表示部分 */
/*  */
#weapon-card {
  width: 100%;
  height: 9.25rem;
  background: repeating-linear-gradient(
    45deg,
    #dbe826,
    #dbe826 20px,
    #c4d412 20px,
    #c4d412 40px
  );
  border-radius: 30px;
  position: relative;
}
/* ブキ表示部分のフッター */
#weapon-footer {
  width: 100%;
  height: 2rem;
  background-color: rgba(0, 0, 0, 0.8);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  position: absolute;
  bottom: 0px;
  padding-top: 0.25rem;
}
/* ツールチップ */
#weaponModal-trigger {
  cursor: pointer;
  position: relative;
}
#weaponModal-trigger:hover .weaponModal-tooltip {
  display: block;
}
.weaponModal-tooltip {
  background: rgba(0, 0, 0, 0.8);
  width: 9.5rem;
  height: 2rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
  position: absolute;
  bottom: -0.3rem;
  left: 23%;
  display: none;
  user-select: none;
}

/* サブとスペシャルの背景 */
.weapon-sub-sp {
  position: relative;
  width: 100%;
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 13px;
}
/* サブとスペシャルの文字 */
.weapon-sub-sp p {
  margin: auto;
}
/* サブとスペシャルの画像 */
.weapon-sub-sp img {
  width: 2.5rem;
  height: 2.5rem;
  margin-top: 0.25rem;
}

/*  */
/* ツールチップ */
/*  */

/* ツールチップの位置固定のためのdiv要素 */
.gear-tooltip-container {
  position: relative;
}

/* 左右の表示位置固定のため矢印を別に用意して固定 */
.gear-tooltip-arrow {
  display: none;
  width: 0.5rem;
  border-left: 0.3rem solid transparent;
  border-right: 0.3rem solid transparent;
  border-top: 0.4rem solid rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 25px;
  left: 60px;
}

/* ギア表示部分のツールチップ */
.gear-tooltip {
  width: 7rem;
  height: 1.8rem;
  z-index: 2;
  padding: 0.3em 0.5em;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 0.3em;
  font-size: 0.9rem;
  user-select: none;
  margin: 0rem;
  position: absolute;
  bottom: 0.4rem;
  left: -3.4rem;
}


.gear-img:hover .gear-tooltip-arrow {
  display: block;
}

/* メインスロットとサブスロットのツールチップ */
.slot-tooltip,
.slot-tooltip-mid {
  display: inline-block;
  /* display: none; */
  z-index: 2;
  padding: 0.3em 0.5em;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 0.3em;
  font-size: 0.9rem;
  user-select: none;
}
/* 右のサブスロットのツールチップ */
.slot-tooltip-right {
  width: 8.8rem;
  height: 1.8rem;
  position: absolute;
  top: -2.2rem;
  left: -6.5rem;
  z-index: 2;
  padding: 0.3em 0.5em;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 0.3em;
  font-size: 0.9rem;
  user-select: none;
}
/* メインスロットのツールチップ */
.slot-tooltip-left {
  display: inline-block;
  z-index: 2;
  padding: 0.3em 0.5em;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 0.3em;
  font-size: 0.9rem;
  user-select: none;
}

.slot-tooltip-left-container {
  width: 8.8rem;
  height: 1.8rem;
  position: absolute;
  top: -2.4rem;
  left: -30px;
  display: none;
  z-index: 32;
}
.slot-tooltip-mid-container {
  width: 8.8rem;
  height: 1.8rem;
  position: absolute;
  top: -2.4rem;
  left: -90px;
  display: none;
  z-index: 32;
}


.slot-tooltip-container {
  width: 8.8rem;
  height: 1.8rem;
  position: absolute;
  top: -2.4rem;
  left: -50px;
  display: none;
  z-index: 32;
}

.slot-tooltip-left:after {
  width: 0.5rem;
  display: block;
  content: '';
  z-index: 2;
  border-top: 0.4rem solid rgba(0, 0, 0, 0.8);
  border-right: 0.3rem solid transparent;
  border-left: 0.3rem solid transparent;
  position: absolute;
  bottom: -0.4rem;
  left: 48px;
}

.slot-tooltip:after {
  width: 0.5rem;
  display: block;
  content: '';
  z-index: 2;
  border-top: 0.4rem solid rgba(0, 0, 0, 0.8);
  border-right: 0.3rem solid transparent;
  border-left: 0.3rem solid transparent;
  position: absolute;
  bottom: -0.4rem;
  left: 65px;
}
.slot-tooltip-mid:after {
  width: 0.5rem;
  display: block;
  content: '';
  z-index: 2;
  border-top: 0.4rem solid rgba(0, 0, 0, 0.8);
  border-right: 0.3rem solid transparent;
  border-left: 0.3rem solid transparent;
  position: absolute;
  bottom: -0.4rem;
  left: 105px;
}

/* 上側に表示する場合だとフォントサイズ変更の際に左右の表示位置が*/
/* 大きくズレやすくなるので矢印を別に作る */
.tooltip-top-arrow {
  width: 0.5rem;
  display: none;
  z-index: 2;
  border-top: 0.4rem solid rgba(0, 0, 0, 0.8);
  border-right: 0.3rem solid transparent;
  border-left: 0.3rem solid transparent;
  position: absolute;
  top: -0.85rem;
  left: 37%;
}
/* ギアとギアパワーの組み合わせが存在しない時の警告ツールチップ */
.slot-tooltip-warning {
  width: 215px;
  height: 4.5rem;
  position: absolute;
  top: -5.25rem;
  left: -15px;
  z-index: 2;
  padding: 0rem 0.5rem 0.2rem 0.5rem;
  color: #ffffff;
  background: #dd293b;
  border-radius: 0.3em;
  font-size: 0.9rem;
  user-select: none;
  margin: 0px;
  /* display: none; */
}
.slot-tooltip-warning:after {
  width: 0.8rem;
  display: block;
  z-index: 2;
  border-top: 0.7rem solid #dd293b;
  border-right: 0.38rem solid transparent;
  border-left: 0.38rem solid transparent;
  position: absolute;
  bottom: -0.7rem;
  left: 30px;
  content: '';
}
/* ツールチップを閉じるボタン */
.tooltip-close-btn {
  width: 20px;
  height: 20px;
  margin-left: 180px;
  cursor: pointer;
}
/* 閉じるボタンのホバーしたとき */
.tooltip-close-btn:hover path {
  fill: #fff;
}

.ability-icon:hover .slot-tooltip-left-container,
.ability-icon:hover .slot-tooltip-mid-container,
.ability-icon:hover .slot-tooltip-container
 {
  display: block;
}

/* ギアモーダル内のツールチップ */
.gear-icon-tooltip {
  display: inline-block;
  z-index: 6;
  padding: 0.2rem 0.4rem;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 0.3em;
  font-size: 0.9rem;
  user-select: none;
  margin: 0rem;
}


.gear-icon:hover .tooltip-container {
  display: block;
}

.tooltip-container {
  display: none;
  position: absolute;
  top: -2.15rem;
  left: -40px;
  width: 200px;
}

.gear-icon-tooltip:after {
  width: 0.5rem;
  display: block;
  content: '';
  z-index: 2;
  border-top: 0.4rem solid rgba(0, 0, 0, 0.8);
  border-right: 0.3rem solid transparent;
  border-left: 0.3rem solid transparent;
  position: absolute;
  bottom: -0.4rem;
  left: 47%;
}

/*  */
/* アビリティモーダル関連 */
/*  */

/* モーダル上部の矢印 */
.mainSlotModal:before {
  border-right: 10px solid transparent;
  border-bottom: 20px solid #6b6b6b;
  border-left: 10px solid transparent;
  z-index: 6;
  display: block;
  content: '';
}

/* サブスロットモーダル */
.subSlotModal-left {
  z-index: 5;
}
.subSlotModal-mid {
  z-index: 5;
}
.subSlotModal-right {
  z-index: 5;
}
.subSlotModal-left:before {
  border-right: 10px solid transparent;
  border-bottom: 20px solid #6b6b6b;
  border-left: 10px solid transparent;
  z-index: 6;
  display: block;
  content: '';
}
.subSlotModal-mid:before {
  border-right: 10px solid transparent;
  border-bottom: 20px solid #6b6b6b;
  border-left: 10px solid transparent;
  z-index: 6;
  display: block;
  content: '';
}
.subSlotModal-right:before {
  border-right: 10px solid transparent;
  border-bottom: 20px solid #6b6b6b;
  border-left: 10px solid transparent;
  z-index: 6;
  display: block;
  content: '';
}

/* アビリティモーダルにおいて共通のスタイル */
.ability-modal {
  border-radius: 10px;
  margin-left: 0px;
  margin-right: 0px;
  background: repeating-linear-gradient(
    45deg,
    #808080,
    #808080 20px,
    #6b6b6b 20px,
    #6b6b6b 40px
  );
  display: none;
  padding-left: 10px;
  z-index: 30;
}

/* アビリティアイコンの大きさ */

.ability {
  width: 50px;
  height: 50px;
  position: relative;
  margin: 5px;
  cursor: pointer;
}

.abilitymodal-img {
  width: 46px;
  height: 46px;
  position: absolute;
  top: 1px;
  left: 1px;
}
/* ギアパワーのツールチップ */
/* 位置固定用のdiv */

.ability-tooltip-container {
  position: absolute;
  top: -2.25rem;
  left: -78px;
  width: 210px;
  display: none;
}
/* ツールチップ本体 */
.ability-tooltip {
  padding: 0.2rem 0.5rem;
  margin: 0rem;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 0.25rem;
  color: #fff;
  z-index: 7;
  font-size: 0.95rem;
  user-select: none;
}
.ability-tooltip:after {
  position: absolute;
  bottom: -0.4rem;
  left: 46%;
  display: block;
  content: '';
  border-top: 0.4rem solid rgba(0, 0, 0, 0.8);
  border-left: 0.3rem solid transparent;
  border-right: 0.3rem solid transparent;
  width: 0.6rem;
}
/* ホバーしたときに表示 */
.ability:hover .ability-tooltip-container {
  display: block;
}

/* ギアモーダルなどのOKボタン */
.OK-btn p {
  transform: scale(1.2, 1);
  letter-spacing: 0.15rem;
  margin-bottom: 0px;
}

/* ギアパワー効果のツールチップ */
.ability-detail-tooltip {
  padding: 0.4rem 0.5rem;
  display: none;
  margin: 0rem;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 0.25rem;
  color: #fff;
  z-index: 7;
  font-size: 1rem;
  user-select: none;
  position: absolute;
  top: -2.9rem;
  left: -8px;
}
/* ツールチップが２行のとき */
.ability-detail-tooltip-2 {
  padding: 0.4rem 0.8rem 0.4rem 0.4rem;
  display: none;
  margin: 0rem;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 0.25rem;
  color: #fff;
  z-index: 7;
  font-size: 1rem;
  user-select: none;
  position: absolute;
  top: -4.5rem;
  left: -8px;
  text-align: right;
}
.ability-detail-tooltip:after,
.ability-detail-tooltip-2:after {
  position: absolute;
  bottom: -0.5rem;
  left: 20px;
  display: block;
  content: '';
  border-top: 0.5rem solid rgba(0, 0, 0, 0.9);
  border-left: 0.4rem solid transparent;
  border-right: 0.4rem solid transparent;
  width: 0.8rem;
}

.ap-ability-icon:hover .ability-detail-tooltip,
.ap-ability-icon:hover .ability-detail-tooltip-2 {
  display: block;
}

/* ギアのリセットボタン */
.gear-reset {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 2.5rem;
  right: 5px;
  cursor: pointer;
}
/* ホバーしたとき拡大と回転 */
.gear-reset:hover svg {
  transition: 0.1s;
  transform: rotate(15deg) scale(1.2, 1.2);
}
/* クリックで回転 */
.gear-reset:active svg {
  transition: 0.1s;
  transform: rotate(-15deg) scale(1.2, 1.2);
}
/* ツールチップ */
.gear-reset-tooltip {
  width: 5rem;
  height: 1.8rem;
  display: none;
  position: absolute;
  bottom: -18px;
  right: 33px;
  z-index: 2;
  padding: 0.2rem 0.3rem;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 0.3rem;
  font-size: 0.9rem;
  user-select: none;
}
/* ツールチップの矢印 */
.gear-reset-tooltip:after {
  border-top: 0.3rem solid transparent;
  border-bottom: 0.3rem solid transparent;
  border-left: 0.4rem solid rgba(0, 0, 0, 0.8);
  content: '';
  display: block;
  width: 0.5rem;
  position: absolute;
  top: 0.6rem;
  right: -0.4rem;
}
/*　ホバーしたときツールチップを表示 */
.gear-reset:hover .gear-reset-tooltip {
  display: block;
}

.toggle-between-modal {
  width: 100%;
  height: 2rem;
}

.caret {
  width: 2rem;
  height: 2rem;
}

.caret-active {
  cursor: pointer;
}

.caret-inactive path {
  fill: none;
  stroke: #dee2e6;
  stroke-width: 20;
}

.caret-active:hover path {
  fill: white;
}

.page-active {
  height: 0.75rem;
  width: 0.75rem;
  background: white;
  border-radius: 50%;
  display: inline-block;
  margin: 0rem 0.25rem 0rem 0.25rem;
  vertical-align: middle;
}

.page-inactive {
  height: 0.75rem;
  width: 0.75rem;
  border: 2px solid #dee2e6;
  border-radius: 50%;
  display: inline-block;
  margin: 0rem 0.25rem 0rem 0.25rem;
  vertical-align: middle;
  cursor: pointer;
}

.page-inactive:hover {
  background: white;
  border: 0px;
}
